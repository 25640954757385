export const config = {
  //Demo
  adminapiUrl: 'https://api.clubinnlife.dci.in/api/V1/AP/',
  venueapiUrl: 'https://api.clubinnlife.dci.in/api/V1/VP/',
  ticketVerifyUrl: 'https://ticket-verify.clubinnlife.dci.in/'
  // ticketVerifyUrl: 'http://localhost:39173/'
  //Demo

  //Live
  // adminapiUrl: 'https://api.clubinn.life/api/V1/AP/',
  // venueapiUrl: 'https://api.clubinn.life/api/V1/VP/',
  // ticketVerifyUrl: 'https://ticket-verify.clubinn.life/'
  //Live

}