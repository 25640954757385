import { Routes } from "@angular/router";
import { AuthGuard } from "./core/services/auth-guard.service";
import { VenueAuthGuard } from "./core/services/venue-auth-guard.service";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";

export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./views/sessions/sessions.module").then(
        (m) => m.SessionsModule
      ),
    data: { title: "Session", breadcrumb: "Session" },
    // children: [ 
    //   {
    //     path: "",
    //     loadChildren: () =>
    //       import("./views/sessions/sessions.module").then(
    //         (m) => m.SessionsModule
    //       ),
    //     data: { title: "Session", breadcrumb: "Session" },
    //   },
    //   {
    //     path: "venue-owner",
    //     loadChildren: () =>
    //       import("./venueowner/sessions/sessions.module").then(
    //         (m) => m.SessionsModule
    //       ),
    //     data: { title: "VenueOwner", breadcrumb: "Session" },
    //   },
    // ],
  },
  {
    path: "happy",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./venueowner/sessions/sessions.module").then(
        (m) => m.SessionsModule
      ),
    data: { title: "VenueOwner", breadcrumb: "Session" },
    // children: [ 
    //   {
    //     path: "",
    //     loadChildren: () =>
    //       import("./views/sessions/sessions.module").then(
    //         (m) => m.SessionsModule
    //       ),
    //     data: { title: "Session", breadcrumb: "Session" },
    //   },
    //   {
    //     path: "venue-owner",
    //     loadChildren: () =>
    //       import("./venueowner/sessions/sessions.module").then(
    //         (m) => m.SessionsModule
    //       ),
    //     data: { title: "VenueOwner", breadcrumb: "Session" },
    //   },
    // ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "venue-owner",
            loadChildren: () =>
              import("./venueowner/sessions/sessions.module").then(
                (m) => m.SessionsModule
              ),
            data: { title: "VenueOwner", breadcrumb: "Session" },
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard", breadcrumb: "Dashboard" },
      },
      {
        path: "terms-conditions",
        loadChildren: () =>
          import(
            "./views/settings/terms-conditions/terms-conditions.module"
          ).then((m) => m.TermsConditionsModule),
        data: { title: "Terms & Conditions", breadcrumb: "Terms & Conditions" },
      },
      {
        path: "ticket-category",
        loadChildren: () =>
          import(
            "./views/settings/ticket-category/ticket-category.module"
          ).then((m) => m.TicketCategoryModule),
        data: { title: "Ticket-Category", breadcrumb: "Ticket-Category" },
      },
      {
        path: "admin/ticket/verify",
        loadChildren: () =>
          import(
            "./views/ticket-verify/ticket-verify.module"
          ).then((m) => m.TicketVerifyModule),
        data: { title: "Ticket Verify", breadcrumb: "Ticket Verify" },
      },
      {
        path: "change-password",
        loadChildren: () =>
          import(
            "./views/settings/change-password/change-password.module"
          ).then((m) => m.ChangePasswordModule),
        data: { title: "Change-Password", breadcrumb: "Change-Password" },
      },
      {
        path: "service-tax",
        loadChildren: () =>
          import("./views/settings/service-tax/service-tax.module").then(
            (m) => m.ServiceTaxModule
          ),
        data: { title: "Service Tax", breadcrumb: "Service Tax" },
      },

      {
        path: "venue-owners",
        loadChildren: () =>
          import("./views/settings/venue-owner/venue-owner.module").then(
            (m) => m.VenueOwnerModule
          ),
        data: { title: "Venue Owner", breadcrumb: "Venue Owner" },
      },
      {
        path: "ratings-reviews",
        loadChildren: () =>
          import(
            "./views/settings/ratings-reviews/ratings-reviews.module"
          ).then((m) => m.RatingsModule),
        data: { title: "Ratings & Reviews", breadcrumb: "Ratings & Reviews" },
      },
      {
        path: "commission-charge",
        loadChildren: () =>
          import(
            "./views/settings/commission-charge/commission-charge.module"
          ).then((m) => m.CommissionChargeModule),
        data: { title: "Commission Charge", breadcrumb: "Commission Charge" },
      },
      {
        path: "artists",
        loadChildren: () =>
          import("./views/artists/artists.module").then((m) => m.ArtistsModule),
        data: { title: "Artists", breadcrumb: "Artists" },
      },
      {
        path: "banner",
        loadChildren: () =>
          import("./views/banner/banner.module").then((m) => m.ReorderModule),
        data: { title: "Banners", breadcrumb: "Banners" },
      },
      {
        path: "events",
        loadChildren: () =>
          import("./views/events/events.module").then((m) => m.EventsModule),
        data: { title: "Events", breadcrumb: "Events" },
      },
      {
        path: "notification-admin",
        loadChildren: () =>
          import("./views/notification-admin/notification-admin.module").then((m) => m.NotificationAdminModule),
        data: { title: "Notification Admin", breadcrumb: "Notification Admin" },
      },
      {
        path: "customers",
        loadChildren: () =>
          import("./views/customer/customer.module").then(
            (m) => m.CustomerModule
          ),
        data: { title: "Customers", breadcrumb: "Customers" },
      },
      {
        path: "places",
        loadChildren: () =>
          import("./views/place/place.module").then((m) => m.PlaceModule),
        data: { title: "Places", breadcrumb: "Places" },
      },
      {
        path: "bookings",
        loadChildren: () =>
          import("./views/booking/booking.module").then((m) => m.BookingModule),
        data: { title: "Bookings", breadcrumb: "Bookings" },
      },
      {
        path: "reports/payment-transaction-report",
        loadChildren: () =>
          import(
            "./views/reports/payment-transaction-report/payment-transaction-report.module"
          ).then((m) => m.PaymentTransactionReportModule),
        data: {
          title: "Payment Transaction Reports",
          breadcrumb: "Payment Transaction Reports",
        },
      },
      {
        path: "payment-transactions",
        loadChildren: () =>
          import("./views/payment-transaction/payment-transaction.module").then(
            (m) => m.PaymentTransactionModule
          ),
        data: {
          title: "Payment Transactions",
          breadcrumb: "Payment Transactions",
        },
      },
      {
        path: "commissions",
        loadChildren: () =>
          import("./views/commissions/commissions.module").then(
            (m) => m.CommissionsModule
          ),
        data: { title: "Commissions", breadcrumb: "Commissions" },
      },
      {
        path: "refund",
        loadChildren: () =>
          import("./views/refund/refund.module").then((m) => m.RefundModule),
        data: { title: "Refund", breadcrumb: "Refund" },
      },

      {
        path: "report/events",
        loadChildren: () =>
          import("./views/reports/event-report/event-report.module").then(
            (m) => m.EventReportModule
          ),
        data: {
          title: "Event Reports",
          breadcrumb: "Event Reports",
        },
      },
      {
        path: "report/refund",
        loadChildren: () =>
          import("./views/reports/refund/refund.module").then(
            (m) => m.RefundReportModule
          ),
        data: {
          title: "Refund Reports",
          breadcrumb: "Refund Reports",
        },
      },
      {
        path: "report/commissions",
        loadChildren: () =>
          import("./views/reports/commissions/commissions.module").then(
            (m) => m.CommissionsReportModule
          ),
        data: {
          title: "Commission Reports",
          breadcrumb: "Commission Reports",
        },
      },
      {
        path: "report/customers",
        loadChildren: () =>
          import("./views/reports/customer/customer.module").then(
            (m) => m.CustomerReportModule
          ),
        data: {
          title: "Customer Reports",
          breadcrumb: "Customer Reports",
        },
      },
      {
        path: "report/bookings",
        loadChildren: () =>
          import("./views/reports/booking/booking.module").then(
            (m) => m.ReportBookingModule
          ),
        data: {
          title: "Booking Reports",
          breadcrumb: "Booking Reports",
        },
      },
      {
        path: "search",
        loadChildren: () =>
          import("./views/search-view/search-view.module").then(
            (m) => m.SearchViewModule
          ),
      },
      {
        path: "notification",
        loadChildren: () =>
          import("./views/notification/notification.module").then((m) => m.NotificationModule),
        data: { title: "Notification", breadcrumb: "Notification" },
      },
    ],
  },
  {
    path: "venue-owner",
    component: AdminLayoutComponent,
    canActivate: [VenueAuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./venueowner/dashboard/dashboard.module").then(
            (m) => m.VenueDashboardModule
          ),
        data: { title: "Dashboard", breadcrumb: "Dashboard" },
      },
      {
        path: "events",
        loadChildren: () =>
          import("./venueowner/events/events.module").then(
            (m) => m.VenueOwnerEventsModule
          ),
        data: { title: "Events", breadcrumb: "Events" },
      },
      {
        path: "bookings",
        loadChildren: () =>
          import("./venueowner/booking/booking.module").then(
            (m) => m.BookingModule
          ),
        data: { title: "Bookings", breadcrumb: "Bookings" },
      },

      {
        path: "report/events",
        loadChildren: () =>
          import(
            "./venueowner/reports/report-events/report-events.module"
          ).then((m) => m.ReportEventsModule),
        data: { title: "Event Reports", breadcrumb: "Event Reports" },
      },
      {
        path: "report/booking",
        loadChildren: () =>
          import(
            "./venueowner/reports/report-booking/report-booking.module"
          ).then((m) => m.ReportBookingModule),
        data: { title: "Booking Reports", breadcrumb: "Booking Reports" },
      },
      {
        path: "ticket/verify",
        loadChildren: () =>
          import(
            "./venueowner/ticket-verify/ticket-verify.module"
          ).then((m) => m.TicketVerifyModule),
        data: { title: "Ticket Verify", breadcrumb: "Ticket Verify" },
      },

      {
        path: "report/payment-transaction-report",
        loadChildren: () =>
          import(
            "./venueowner/reports/payment-transacion-report/payment-transaction-report.module"
          ).then((m) => m.PaymentTransactionReportModule),
        data: {
          title: "Payment Transaction Reports",
          breadcrumb: "Payment Transaction Reports",
        },
      },
      {
        path: "venue-notification",
        loadChildren: () =>
          import("./venueowner/venue-notification/venue-notification.module").then((m) => m.VenueNotificationModule),
        data: { title: "venue-notification", breadcrumb: "venue-notification" },
      },

    ],
  },


];
